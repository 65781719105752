import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { ethers } from 'ethers'
import abi from './abi'
import chains from './chains'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tokens: null,
    tokensValid: false,
    tokenCount: 0,
    chainId: null,
    platform: chains['0x1'].platform,
    explorerUrl: chains['0x1'].explorer,
    contractAddress: chains['0x1'].contract,
    account: null,
  },
  mutations: {
    invalidateTokens(state) {
      state.tokensValid = false
    },
    chainId(state, chainId) {
      state.chainId = chainId
      state.platform = chains[chainId].platform
      state.explorerUrl = chains[chainId].explorer
      state.contractAddress = chains[chainId].contract
    },
    account(state, account) {
      state.account = account
    },
  },
  getters: {
    tokensValid: (state) => state.tokensValid,
    apiURL: () => { return process.env.NODE_ENV === 'production' ? '' : 'http://localhost:4000'},
    chainId: (state) => state.chainId,
    platform: (state) => state.platform,
    platformFor: () => (chainId) => chains[chainId].platform,
    platformName: () => (chainId) => chains[chainId].name,
    platformFullname: () => (chainId) => chains[chainId].fullname,
    explorerUrl: (state) => state.explorerUrl,
    explorerUrlFor: () => (chainId) => chains[chainId].explorer,
    rpcUrlFor: () => (chainId) => chains[chainId].rpc_url,
    symbolFor: () => (chainId) => chains[chainId].symbol,
    contractAddress: (state) => state.contractAddress,
    account: (state) => state.account,
  },
  actions: {
    getTokens(context, options) {
      return new Promise((resolve, reject) => {
        if(context.state.tokensValid) {
          resolve({ count: context.state.tokenCount, data: context.state.tokens })
        } else {
          var apiURL = context.getters.apiURL
          apiURL = `${apiURL}/api/v1/token/list?page=${options.page}&items=${options.items}&sort=${options.sort}&desc=${options.desc}`
          apiURL = options.search ? `${apiURL}&search=${options.search}` : apiURL
          axios.get(apiURL).then(async (response) => {
            context.state.tokenCount = response.data.count
            context.state.tokens = response.data.data
            context.state.tokensValid = true
            resolve(response.data)
          }).catch((err) => {
            reject(err)
          })
        }
      })
    },
    getToken(context, slug) {
      return new Promise((resolve, reject) => {
        if(context.state.tokens) {
          var token = context.state.tokens.filter((t) => {
            return t.slug == slug
          })[0]
          if(token) {
            resolve(token)
          } else {
            getToken()
          }
        } else {
          getToken()
        }

        function getToken() {
          var apiURL = context.getters.apiURL
          axios.get(apiURL + '/api/v1/token/' + slug).then(async (response) => {
            resolve(response.data)
          }).catch((err) => {
            reject(err)
          })
        }
      })
    },
    getTokenReviews(context, slug) {
      return new Promise((resolve, reject) => {
        var apiURL = context.getters.apiURL
        axios.get(apiURL + '/api/v1/token/' + slug).then(async (response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getReview(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(context.getters.apiURL + '/api/v1/review/get', payload).then(async (response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    saveReview(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(context.getters.apiURL + '/api/v1/review/save', payload).then(async (response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    setRating(context, payload) {
      return new Promise((resolve, reject) => {
        var provider = new ethers.providers.Web3Provider(window.ethereum)
        var contract = new ethers.Contract(context.state.contractAddress, abi, provider)
        var contractWithSigner = contract.connect(provider.getSigner())
        var score = ethers.utils.parseUnits(String(payload.rating))

        contractWithSigner.setRating(payload.token_address, score, payload.scam).then((transaction) => {
          transaction.wait().then((receipt) => {
            resolve(receipt)
          }).catch((error) => {
            reject(error)
          })
        }).catch((error) => {
          reject(error)
        })
      })
    },
    signMessage() {
      return new Promise((resolve, reject) => {
        var provider = new ethers.providers.Web3Provider(window.ethereum)
        var signer = provider.getSigner()

        var message = 'Click "Sign" to verify your account. This will not create a transaction or cost any fees.'
        signer.signMessage(message).then((signature) => {
          resolve(signature)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getBalance(context, token_address) {
      return new Promise((resolve, reject) => {
        var provider = new ethers.providers.Web3Provider(window.ethereum)
        var contract = new ethers.Contract(token_address, abi, provider)

        contract.decimals().then((decimals) => {
          balanceOf(decimals)
        }).catch(() => {
          balanceOf(0)
        })

        function balanceOf(decimals) {
          contract.balanceOf(context.state.account).then((balance) => {
            resolve(ethers.utils.formatUnits(balance, decimals))
          }).catch((error) => {
            reject(error)
          })
        }
      })
    },
    switchChain(context, chain_id) {
      return new Promise((resolve) => {
        if(window.ethereum) {
          window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chain_id }],
          }).then(() => {
            resolve()
          }).catch((error) => {
            if (error.code === 4902) {
              window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: chain_id,
                    chainName: context.getters.platformFullname(chain_id),
                    rpcUrls: [context.getters.rpcUrlFor(chain_id)],
                    blockExplorerUrls: [context.getters.explorerUrlFor(chain_id)],
                    nativeCurrency: {
                      symbol: context.getters.symbolFor(chain_id),
                      decimals: 18,
                    },
                  },
                ],
              }).then(() => {
                resolve()
              }).catch(() => {
                resolve()
              });
            }
          });
        }
      })
    },
  },
  modules: {
  }
})
