var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"card"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","background-color":"white","hide-details":"","clearable":"","outlined":"","rounded":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"d-none d-md-inline-block ml-4",attrs:{"fab":"","small":"","elevation":"0"},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"tokens card",attrs:{"headers":_vm.headers,"items":_vm.tokens,"items-per-page":10,"loading":_vm.loading,"search":_vm.search,"no-data-text":"No tokens found","no-results-text":"No tokens found","loading-text":"Loading tokens...","server-items-length":_vm.tokenCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/token/' + item.slug}},[_c('v-img',{staticClass:"float-left mr-4",attrs:{"src":item.logo,"max-width":"25","max-height":"25","contain":"","eager":""}}),_c('span',{staticClass:"body-1"},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('span',{staticClass:"body-2 px-2 grey--text hidden-sm-and-down"},[_vm._v(" "+_vm._s(item.symbol)+" ")])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(t){return _c('v-chip',{key:t,staticClass:"mr-2",attrs:{"color":"blue lighten-5","small":""}},[_vm._v(" "+_vm._s(t)+" ")])})}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{staticClass:"float-left",attrs:{"color":"yellow darken-2","background-color":"blue-grey lighten-3","half-increments":"","readonly":""},model:{value:(item.rating),callback:function ($$v) {_vm.$set(item, "rating", $$v)},expression:"item.rating"}})]}},{key:"item.review_count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption mt-2"},[_vm._v(" "+_vm._s(item.review_count || 0)+" reviews ")])]}},{key:"item.reward",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ms-3",attrs:{"color":item.reward > 0 ? 'green' : 'lime',"rounded":"","outlined":"","x-small":"","to":'/review/' + item.slug}},[_vm._v(" "+_vm._s(item.reward)+" VIEW ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }