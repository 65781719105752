import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import '@/styles/styles.css'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueGtag, {
  config: { id: 'G-Z0Q4ZMY2SB' }
}, router);

Vue.filter('toNumber', (value) => {
  if (Number.isNaN(parseFloat(value))) {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  return formatter.format(value)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
