<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import { Chart } from 'highcharts-vue'
import colors from 'vuetify/lib/util/colors';

export default {
  components: {
    highcharts: Chart
  },
  data() {
    return {
      chartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'Token Distribution'
        },
        subtitle: {
          text: 'The total token supply will be distributed to the following:'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.0f}%'
            },
            showInLegend: true,
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          itemMarginTop: 15,
        },
        colors: [
          colors.blue.lighten2,
          colors.cyan.lighten2,
          colors.green.lighten2,
          colors.lime.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [
          {
            name: 'Amount',
            colorByPoint: true,
            innerSize: '50%',
            data: [
              {
                name: 'Community rewards',
                y: 28,
              },
              {
                name: 'Investors',
                y: 25,
              },
              {
                name: 'Team members',
                y: 15,
              },
              {
                name: 'Liquidity pools',
                y: 10,
              },
              {
                name: 'Reserve',
                y: 8,
              },
              {
                name: 'Advisors',
                y: 5,
              },
              {
                name: 'Airdrop',
                y: 5,
              },
              {
                name: 'Bounty',
                y: 4,
              },
            ]
          }
        ]
      }
    }
  }
}
</script>
