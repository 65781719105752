import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken2,
        secondary: colors.blue.accent1,
        accent: colors.amber.lighten2,
        error: colors.red.darken1,
        info: colors.blue,
        success: colors.green.darken1,
        warning: colors.orange.darken1,
        card: '#fbfeff',
        eth: '#e0e0ff',
        bsc: '#fdefcc',
        pol: '#e6d6ff',
        fan: '#d2e2ff',
        ava: '#fbdddd',
      },
    },
  },
});
