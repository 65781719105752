<template>
  <v-chip
    v-if="account"
    :class="large ? 'ps-6' : 'ps-4'"
    :large="large"
    :small="small"
  >
    <identicon :account="account" class="identicon"></identicon>
    <span class="account">{{ account }}</span>
  </v-chip>
</template>

<script>
import Identicon from './Identicon.vue'

export default {
  components: {
    Identicon,
  },
  props: {
    account: {
      type: String,
      default: undefined,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.identicon {
  width: 18px !important;
  height: 18px !important;
}
.account {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
