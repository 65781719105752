import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/token/:slug?',
    name: 'token',
    component: () => import(/* webpackChunkName: "token" */ '../views/Token.vue'),
  },
  {
    path: '/review/:slug?',
    name: 'review',
    component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
  },
  {
    path: '*',
    redirect: 'error',
  },
]

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

export default router
