<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="'text-none ' + (account ? 'v-btn--active' : '')"
          color="primary"
          rounded
          outlined
          :large="large"
          :small="small"
          v-bind="attrs"
          v-on="on"
        >
          <identicon v-if="account" :account="account" class="identicon"></identicon>
          <span class="d-none d-sm-block">
            {{ walletTextLong() }}
          </span>
          <span class="d-sm-none">
            {{ walletTextShort() }}
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span v-if="account">Account</span>
          <span v-if="!account">Connect to a wallet</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="ethereum && account">
          <p>Connected with MetaMask.</p>
          <account :account="account" class="px-6 mb-4" large></account>
          <p>To disconnect use the MetaMask user interface.</p>
        </v-card-text>
        <v-card-text v-if="ethereum && !account">
          <v-btn
            rounded
            outlined
            class="text-none mb-4"
            @click="connectWallet"
          >
            Connect with MetaMask
          </v-btn>
        </v-card-text>
        <v-card-text v-if="!ethereum">
          <v-btn
            rounded
            outlined
            class="text-none mb-4"
            href="https://metamask.io/"
            target="_blank"
          >
            Install MetaMask
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-chip
      v-if="chainWarning()"
      color="warning"
      class="d-none d-md-inline-flex ms-4"
      :large="large"
      :small="small"
    >
      {{ chainText() }}
    </v-chip>
    <v-snackbar
      v-model="alert"
      :color="alertType"
      timeout="10000"
      outlined
      app
      top
      right
      text
    >
      {{ alertText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          @click="alert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import Identicon from './Identicon.vue'
import Account from './Account.vue'

export default {
  components: {
    Identicon,
    Account,
  },
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ethereum: false,
      chainId: null,
      account: null,
      dialog: false,
      alert: false,
      alertType: null,
      alertText: null,
    }
  },
  beforeMount() {
    this.getChain()
    this.getAccount()
  },
  watch: {
    alertText: function() {
      this.alert = true
    },
  },
  methods: {
    getChain() {
      if (window.ethereum) {
        this.ethereum = true
        window.ethereum.autoRefreshOnNetworkChange = false
        window.ethereum.request({
          method: 'eth_chainId',
        }).then((chainId) => {
          this.chainId = chainId
          this.$store.commit('chainId', this.chainId)

          window.ethereum.on('chainChanged', (chainIdChanged) => {
            this.chainId = chainIdChanged
            this.$store.commit('chainId', this.chainId)
          })
        }).catch((error) => {
          this.alertType = 'error'
          this.alertText = error.message
        })
      }
    },
    getAccount() {
      if (window.ethereum) {
        window.ethereum.request({
          method: 'eth_accounts',
          params: [{}],
        }).then((accounts) => {
          this.account = accounts[0] ? ethers.utils.getAddress(accounts[0]) : null
          this.$store.commit('account', this.account)

          window.ethereum.on('accountsChanged', (accounts) => {
            this.account = accounts[0] ? ethers.utils.getAddress(accounts[0]) : null
            this.$store.commit('account', this.account)
          })
        }).catch((error) => {
          this.alertType = 'error'
          this.alertText = error.message
        })
      }
    },
    connectWallet() {
      if (window.ethereum) {
        if (!this.account) {
          window.ethereum.request({
            method: 'eth_requestAccounts',
            params: [{}],
          }).then((accounts) => {
            this.account = accounts[0] ? ethers.utils.getAddress(accounts[0]) : null
            this.dialog = false
          }).catch((error) => {
            this.alertType = 'error'
            this.alertText = error.message
          })
        }
      }
    },
    chainText() {
      var text = 'No Network'
      if (this.chainId === '0x1') {
        text = 'Main Network'
      } else if (this.chainId === '0x3') {
        text = 'Ropsten Test Network'
      } else if (this.chainId === '0x4') {
        text = 'Rinkeby Test Network'
      } else if (this.chainId === '0x5') {
        text = 'Goerli Test Network'
      } else if (this.chainId === '0x2a') {
        text = 'Kovan Test Network'
      } else {
        text = 'Unknown Network'
      }

      return text
    },
    chainWarning() {
      return this.warning && this.chainId !== null && this.chainId !== '0x1'
    },
    walletTextLong() {
      var text = 'Connect Wallet'
      if (this.account) {
        text = this.account.slice(0, 6) + '...' + this.account.slice(-4)
      }

      return text
    },
    walletTextShort() {
      var text = 'Connect'
      if (this.account) {
        text = this.account.slice(0, 4) + '...' + this.account.slice(-2)
      }

      return text
    },
  },
}
</script>

<style scoped>
.identicon {
  width: 18px !important;
}
</style>
