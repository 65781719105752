<template>
  <v-card color="card">
    <v-card-title>
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        background-color="white"
        hide-details
        clearable
        outlined
        rounded
        dense
      ></v-text-field>
      <v-btn
        fab
        small
        elevation="0"
        class="d-none d-md-inline-block ml-4"
        @click="refresh"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="tokens"
      :items-per-page="10"
      :loading="loading"
      :search="search"
      class="tokens card"
      no-data-text="No tokens found"
      no-results-text="No tokens found"
      loading-text="Loading tokens..."
      :server-items-length="tokenCount"
      :options.sync="options"
    >
      <template #[`item.name`]="{item}">
        <router-link class="text-decoration-none" :to="'/token/' + item.slug">
          <v-img
            :src="item.logo"
            max-width="25"
            max-height="25"
            class="float-left mr-4"
            contain
            eager
          ></v-img>
          <span class="body-1">
            {{ item.name }}
          </span>
        </router-link>
        <span class="body-2 px-2 grey--text hidden-sm-and-down">
          {{ item.symbol }}
        </span>
      </template>

      <template #[`item.tags`]="{item}">
        <v-chip
          v-for="t in item.tags"
          :key="t"
          color="blue lighten-5"
          class="mr-2"
          small
        >
          {{ t }}
        </v-chip>
      </template>

      <template #[`item.rating`]="{item}">
        <v-rating
          v-model="item.rating"
          color="yellow darken-2"
          background-color="blue-grey lighten-3"
          class="float-left"
          half-increments
          readonly
        ></v-rating>
      </template>

      <template #[`item.review_count`]="{item}">
        <span class="text-caption mt-2">
          {{ item.review_count || 0 }} reviews
        </span>
      </template>

      <template #[`item.reward`]="{item}">
        <v-btn
          class="ms-3"
          :color="item.reward > 0 ? 'green' : 'lime'"
          rounded
          outlined
          x-small
          :to="'/review/' + item.slug"
        >
          {{ item.reward }} VIEW
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
.list >>> .v-list-item__icon {
  margin-right: 12px !important;
}
.list >>> .v-list-item__icon i {
  font-size: 18px;
}
.list >>> .v-list-item__title, .list >>> .v-list-item__subtitle {
  font-weight: 400 !important;
  white-space: normal;
}
</style>

<script>
export default {
  data: () => ({
    loading: false,
    search: '',
    headers: [
      { text: 'Name',     value: 'name',         sortable: true,  align: 'start name' },
      { text: 'Symbol',   value: 'symbol',       sortable: true,  align: 'start d-none' },
      { text: 'Tags',     value: 'tags',         sortable: false, align: 'start d-none d-lg-table-cell' },
      { text: 'Rating',   value: 'rating',       sortable: true,  align: 'start' },
      { text: 'Reviews',  value: 'review_count', sortable: true,  align: 'start' },
      { text: 'Reward',   value: 'reward',       sortable: true,  align: 'right' },
    ],
    tokens: [],
    tokenCount: 0,
    options: {},
  }),
  watch: {
    search: function() {
      this.$store.commit('invalidateTokens')
      this.options.page = 1
      this.load()
    },
    'options.page': {
      deep: true,
      handler() {
        this.$store.commit('invalidateTokens')
        this.load()
      }
    },
  },
  methods: {
    load() {
      this.loading = true
      var options = {
        page: this.options.page,
        items: this.options.itemsPerPage,
        sort: this.options.sortBy[0] || 'rank',
        desc: this.options.sortDesc[0] || false,
        search: this.search,
      }
      this.$store.dispatch('getTokens', options).then((response) => {
        this.tokens = response.data
        this.tokenCount = response.count
        this.loading = false
      }).catch(() => {
        this.tokens = []
        this.loading = false
      })
    },
    refresh() {
      this.$store.commit('invalidateTokens')
      this.load()
    },
  },
}
</script>

<style scoped>
.tokens >>> .name {
  min-width: 180px;
}
</style>
