<template>
  <v-app>
    <v-app-bar
      app
      elevation="0"
      class="menu"
      color="#ebf6ff"
    >
      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
          alt="Metaview Logo"
          class="shrink mr-2 float-left"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="35"
          eager
        />

        <v-img
          alt="Metaview"
          class="shrink mt-2 hidden-sm-and-down"
          contain
          :src="require('@/assets/name.png')"
          width="100"
          eager
        />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn text rounded color="primary" class="mx-1 mx-md-3" to="/">Explore</v-btn>
      <v-btn text rounded color="primary" class="mx-1 mx-md-3" to="/review">Review</v-btn>
      <v-btn text rounded color="primary" class="mx-1 mx-md-3" href="https://docs.metaview.io" target="_blank">
        Docs
        <v-icon right small color="secondary">mdi-open-in-new</v-icon>
      </v-btn>
      <wallet color="primary" class="mx-1 mx-md-3"></wallet>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app absolute>
      <v-container fluid>
        <v-layout>
          <v-flex md6 class="pt-2">
            <span>© {{ new Date().getFullYear() }} Metaview</span>
          </v-flex>
          <v-flex md6 class="text-right">
            <v-btn icon class="ms-2" href="https://twitter.com/metaviewio" target="_blank">
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn icon class="ms-2" href="https://www.instagram.com/metaviewio" target="_blank">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn icon class="ms-2" href="https://github.com/metaviewio" target="_blank">
              <v-icon>mdi-github</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import Wallet from './components/wallet/Wallet.vue';

export default {
  components: { Wallet },
  name: 'App',
  data: () => ({
  }),
};
</script>

<style scoped>
.menu {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #1976D2, #AD1457, #0D47A1, #1976D2);
}
</style>
