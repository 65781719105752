<template>
  <div>
    <div class="bg-fade bg-purple bg-top-left"></div>
    <div class="bg-fade bg-yellow bg-top-right"></div>
    <div class="bg-fade bg-purple bg-center-center"></div>
    <div class="bg-fade bg-blue bg-bottom-center"></div>
    <v-container class="my-4 my-md-8">
      <v-row>
        <v-col cols="12" md="8" class="text-center text-md-left">
          <h1 class="text-h5 mb-4">
            Decentralised <span class="purple--text text--darken-4">crypto reviews</span> and <span class="purple--text text--darken-4">metaverse explorer</span>
          </h1>
          <p class="text-subtitle-1 blue-grey--text mb-4">
            Metaview provides free, transparent and easily accessible data about crypto tokens.
          </p>
          <p class="text-subtitle-1 blue-grey--text mb-6">
            So you can make informed decisions and avoid scams.
          </p>
          <v-btn
            class="text-none"
            color="primary"
            rounded
            to="/review"
          >
            Review Tokens and Earn VIEW Now
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="hidden-sm-and-down">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img :src="require('@/assets/eth.svg')" alt="Ethereum">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Ethereum</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <img :src="require('@/assets/bsc.svg')" alt="Binance Smart Chain">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Binance Smart Chain</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar style="border-radius: unset;">
                <img :src="require('@/assets/pol.svg')" alt="Polygon">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Polygon</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-4">
      <token-table></token-table>
    </v-container>
    <v-container class="my-4 text-center">
      <h2 class="text-h5 my-6">The Purpose of Metaview</h2>
      <p class="text-subtitle-1 blue-grey--text">There are over 16 000 tokens and more are being created everyday.</p>
      <p class="text-subtitle-1 blue-grey--text">Hacks and scams have become a major problem because the credibility and security of tokens is hard to determine.</p>
      <p class="text-subtitle-1 blue-grey--text">Metaview is the first token review platform with on-chain data and provides the following services.</p>

      <v-row justify="space-around">
        <v-col cols="12" sm="8" md="6">
          <v-list class="list text-left">
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="35" color="amber lighten-2">mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Community Reviews</v-list-item-title>
                <v-list-item-subtitle>Web3 review platform with on-chain ratings accessible to decentralised apps.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon style="border-radius: unset;">
                <v-icon size="35" color="green lighten-2">mdi-shield-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Security Audits</v-list-item-title>
                <v-list-item-subtitle>Professional security audits, automated code analysis and vulnerability scanning.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="35" color="blue lighten-2">mdi-chart-areaspline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>On-chain Analytics</v-list-item-title>
                <v-list-item-subtitle>On-chain analytics platform and multi-chain explorer.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-4 text-center">
      <h2 class="font-weight-regular my-6">Token Economics</h2>
      <p class="text-subtitle-1 blue-grey--text">The VIEW token is an incentive and governance token for the system.</p>
      <p class="text-subtitle-1 blue-grey--text">Demand for the token will come from:</p>
      <v-row justify="space-around">
        <v-col cols="12" sm="8" md="6">
          <v-list dense class="list text-left mb-10">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="grey">mdi-currency-usd</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Premium Features</v-list-item-title>
                <v-list-item-subtitle>Holders of VIEW tokens will have access to premium features.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="grey">mdi-fire</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Token Buy Backs</v-list-item-title>
                <v-list-item-subtitle>A portion of profits from security audits will be used to buy back tokens.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon style="border-radius: unset;">
                <v-icon color="grey">mdi-vote</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Governance</v-list-item-title>
                <v-list-item-subtitle>Token holders will be able to vote on major decisions.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <p class="text-subtitle-1 blue-grey--text">Supply of the token will come from:</p>
      <v-row justify="space-around">
        <v-col cols="12" sm="8" md="6">
          <v-list dense class="list text-left mb-10">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="grey">mdi-seal</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Community Rewards</v-list-item-title>
                <v-list-item-subtitle>Users earn rewards for each review and stand a chance to win airdropped prizes.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="grey">mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Team and Advisors</v-list-item-title>
                <v-list-item-subtitle>Tokens will be locked and will be released over a three year vesting period.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon style="border-radius: unset;">
                <v-icon color="grey">mdi-nature-people</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Investors</v-list-item-title>
                <v-list-item-subtitle>Tokens will be distributed to investors in each funding round.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="12" md="5">
          <token-distribution></token-distribution>
        </v-col>
        <v-col cols="12" md="5">
          <fund-allocation></fund-allocation>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-4 text-center">
      <h2 class="font-weight-regular my-6">Project Roadmap</h2>
      <p class="text-subtitle-1 blue-grey--text mb-6">The project roadmap outlines the major development and financial milestones and is subject to change.</p>
      <roadmap></roadmap>
    </v-container>
  </div>
</template>

<script>
import TokenDistribution from '../components/charts/TokenDistribution.vue'
import FundAllocation from '../components/charts/FundAllocation.vue'
import Roadmap from '../components/Roadmap.vue'
import TokenTable from '../components/TokenTable.vue'

export default {
  components: {
    TokenDistribution,
    FundAllocation,
    Roadmap,
    TokenTable,
  },
  metaInfo() {
    return {
      title: 'Metaview: Token Reviews and Blockchain Explorer',
    }
  },
}
</script>

<style scoped>
.list >>> .v-list-item__title, .list >>> .v-list-item__subtitle {
  font-weight: 400 !important;
  white-space: normal;
}
</style>
